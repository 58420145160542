<template>
  <div class="content">
	<headers :marage="159" :selectIndex="6">
	</headers>
    <!-- <header class="header">
      <Header />
    </header> -->
    <div class="container">
      <!-- banner -->
      <div class="banner"></div>
      <!-- 选项卡切换 -->
      <div class="wrapper" style="margin-top: 40px;">
        <!--选项卡-->
        <van-tabs v-model="active" animated>
          <van-tab title="关于我们">
            <About />
          </van-tab>
          <van-tab title="精英团队">
            <Group />
          </van-tab>
          <van-tab title="加入我们">
            <Join />
          </van-tab>
          <van-tab title="联系我们">
            <Call />
          </van-tab>
        </van-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Tab, Tabs } from "vant";
	import headers from "../../components/header.vue";

Vue.use(Tab);
Vue.use(Tabs);
import Join from "./joinus.vue";
// import Header from "../../components/header.vue";
import About from "./aboutus.vue";
import Group from "./group.vue";
import Call from "./callme.vue";
export default {
  components: {
    // Header,
    About,
    Group,
    Join,
    Call,
	headers
  },
  data() {
    return {
      active: 0,
    };
  },
};
</script>
<style lang="stylus" scoped>
.banner
  height 1.60rem
  width 100%
  background url(../../assets/img/myimg/banner.png)
  background-size 100%
</style>
