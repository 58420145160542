<template>
  <div>
    <div class="group-die">
      <div class="group" v-for="item of teams" :key="item.id" style="margin: 8px">
        <van-image
          round
          fit="cover"
          width="89px"
          height="89px"
          object-fit="cover"
          :src="item.image_url"
          @click="go(item.id)"
          style="margin-top: 17px; border:1px solid #F5F5F5;"
        />
        <b class="name" @click="go(item.id)">{{ item.name }}</b
        ><br />
        <span class="post" @click="go(item.id)">{{ item.position }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { Image as VanImage } from "vant";
import { getTeams } from "../../api/api";
Vue.use(VanImage);
export default {
  data() {
    return {
      teams: [],
    };
  },
  mounted() {
    getTeams().then((res) => {
      this.teams = this.changObjByColumn(res.data.data.lists, 'sort');
    });
  },
  methods: {
    go(id) {
      this.$router.push("groupdetail/" + id);
    },
    changObjByColumn (data, column) {
      let obj = {}
      for (let i in data) {
        obj[data[i][column]] = data[i]
      }
      return obj
    }
  },
};
</script>
<style lang="stylus" scoped>
.group-die
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center
  margin 30px 0
.group
  width: 1.57rem;
  height: 1.95rem;
  text-align center
  justify-content: space-evenly;box-shadow: 3px 1px 20px 2px rgba(153, 153, 153, 0.13);
  border-radius: 10px;

.name
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 900;
  color: #021212;
  line-height: 19px;
  display block
  padding-top 18px
.post
  font-size: 13px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 10px;
</style>
