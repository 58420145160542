<template>
  <div>
    <div>
      <div class="cloud">
        <div class="cloud-yun">
          <h2>办公环境</h2>
          <img src="../../assets/img/productcenterimg/heng.png" alt="" />
        </div>
      </div>
      <div class="imgge">
        <swiper :options="swiperOption" ref="mySwiper">
          <swiper-slide
            v-for="imgge of companyStyles"
            :key="imgge.id"
            style="width: 270px"
          >
            <img :src="'http://qkw-api.oss-cn-beijing.aliyuncs.com/' + imgge.path" alt="" />
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
        <div class="swipe-img">
          <!-- <img src="@/assets\img\myimg/left.png" alt="" /> -->
          <!-- <img src="@/assets\img\myimg/right.png" alt="" /> -->
        </div>
      </div>
    </div>
    <div class="swipeTwo">
      <div class="cloud">
        <div class="cloud-yun">
          <h2 style="color: #fff">员工活动</h2>
          <img src="../../assets/img/productcenterimg/heng.png" alt="" />
        </div>
      </div>
      <van-swipe
        class="my-swipe"
        :autoplay="300000"
        indicator-color="white"
        :width="275"
        :show-indicators="false"
        :loop="false"
      >
        <van-swipe-item
          v-for="item of staffActivity"
          :key="item.id"
          style="
            background-color: #fff;
            margin-top: 0.25rem;
            margin-left: 0.3rem;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
          "
        >
          <img :src="item.image_url" class="swipeImg" />
          <div class="activit">
            <div class="activityword">{{ item.title }}</div>
            <div class="activitytext">
              {{ item.content }}
            </div>
          </div>
        </van-swipe-item>
      </van-swipe>

      <!-- <van-swipe :width="277" :show-indicators="false">
        <van-swipe-item v-for="item of staffActivity" :key="item.id"  style="background-color: #fff">
          <div class="borderbox">
            <div class="">
              <img
                :src="item.image_url"
                class="swipeImg"
               
              />
            </div>
            <div class="activit">
              <div class="activityword">{{ item.title }}</div>
              <div class="activitytext">
                {{ item.content }}
              </div>
            </div>
          </div>
        </van-swipe-item>
      </van-swipe> -->
    </div>
    <div class="talents">
      <div>
        <div class="cloud">
          <div class="cloud-yun">
            <h2>人才招聘</h2>
            <img src="../../assets/img/productcenterimg/heng.png" alt="" />
          </div>
        </div>
        <div
          class="talents-word"
          v-for="item of recruitment"
          :key="item.id"
          @click="go(item.id)"
        >
          <div class="talents-name">{{ item.post_name }}</div>
          <div class="talents-mun">
            工作地点：{{ item.workplace }} 招聘人数：{{ item.number }} 人
          </div>
          <div>
            <div class="talents-detail">了解详情 ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { Swipe, SwipeItem } from "vant";
import { getRecruitment, getCompanyStyles, getStaffActivity } from "../../api/api";

import "swiper/css/swiper.css";

// import 'swiper/dist/css/swiper.css';
Vue.use(Swipe);
Vue.use(SwipeItem);
export default {
  data() {
    return {
      recruitment: [],
      companyStyles: [],
      staffActivity: [],

      swiperOption: {
        autoplay: true,
        delay: 3000,
        disableOnInteraction: false,
        loop: true,
        effect: "coverflow",
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: 1.2,
        coverflowEffect: {
          //修改其中的数值，即可
          rotate: -50, //旋转
          stretch: 50, //拉伸
          depth: 150, //深度
          modifier: 1,
          slideShadows: true,
        },
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
      },
    };
  },
  methods: {
    go(id) {
      this.$router.push({
        path: "/joinusdetail",
        query: { id },
      });
    },
  },
  mounted() {
    getRecruitment().then((res) => {
      this.recruitment = res.data.data.lists;
    });
    getCompanyStyles().then((res) => {
      this.companyStyles = res.data.data
    });
    getStaffActivity().then((res) => {
      this.staffActivity = res.data.data.lists;
    });
  },
};
</script>
<style scoped>
.swiper-container {
  width: 3.75rem;
  height: 2.5rem;
}
.swiper-slide {
  background-color: #fff;
}
</style>
<style lang="stylus" scoped>
.swipeImg
    height 1.54rem
    width 275px
    border-top-left-radius 5px
    border-top-right-radius 5px
.swiper-container .van-swipe-item
  text-align: center;
.talents
  padding-bottom 0.3rem
.talents-word
  margin 10px auto 0
  width: 3.4rem
  height: 0.75rem;
  background: #FFFFFF;
  border-radius: 4px;
  border-left 2px solid #F95F52
  box-shadow: 2px 1px 16px 2px rgba(153, 153, 153, 0.15);
  .talents-name
    padding-top .1rem
    line-height 0.33rem
    font-weight 600
    padding-left 0.2rem
    font-size 15px
  .talents-detail
    display: block;
    float: right;
    margin-top -0.28rem
    margin-right .1rem
    font-size 12px
    color: #999999;
  .talents-mun
    padding-left .2rem
.cloud
  display flex
  justify-content center
  text-align center
  line-height .2rem
  .cloud-yun
    margin-top 0.3rem
    h2
      font-size: 0.19rem;
      font-family: PingFang SC;
      font-weight: 600;
      color: #212121;
      line-height: 0.26rem;
      width 3rem
    span
      display block
      line-height: 26px
      color #999
      font-weight: 600
      width 2.9rem
    img
      width 0.38rem
      height 0.03rem
.sansTwo
    width 0.9rem
    height 0.94rem
    float left
    margin-top 0.5rem
    .point
        width 0.1rem
        height 0.1rem
        background: #F85C4E
        border-radius: 50%
        margin  0 auto
    .time
        width 1rem
        font-size 25px
        line-height 40px
        height 0.2rem
        color #ffffff
        align-items center
        text-align center
    .timeTo
        width: 1rem;
        height: 0.26rem;
        font-size: 14px;
        font-family: PingFang SC;
        color: #FFFFFF;
        line-height: 53px;
        text-align center
.swipeTwo
  background url(../../assets/img/myimg/bg_one.png)
  background-size 100%
  height 4rem
.imgge
  img
    width 100%
    height 2rem
    margin-top 30px
.swipe-img
  display flex
  justify-content: center
  img
    height 1rem
    width 1rem
    margin-top 0
.activityword
  width 2rem
  color #333333
  font-size 16px
  padding 10px
  font-weight  700
.activitytext
  color #999999
  width 2rem
  padding 0 10px 0px 10px
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
.activit
  width 275px
  background-color #fff
  height 1rem
  border-bottom-left-radius 5px
  border-bottom-right-radius 5px
.borderbox
  width 2.5rem
  // overflow hidden
</style>
<style>
/* .swiper-slide .swiper-slide-duplicate .swiper-slide-duplicate-prev {
  width: 2.75rem !important;
} */
</style>
