var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_c('div',{staticStyle:{"margin-bottom":"30px"}},[_vm._m(5),_c('div',_vm._l((_vm.fazhanList),function(item){return _c('div',{key:item.id,staticClass:"news"},[_c('img',{staticClass:"quan",attrs:{"src":require("../../assets/img/homeimg/ic_doc.png")}}),_c('div',{staticClass:"month"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"newsert"},[_c('div',{staticClass:"newser"},[_c('div',{staticClass:"newserbg"},[_c('div',{staticClass:"shang"},[_vm._v(" "+_vm._s(item.text)+" ")]),_c('img',{attrs:{"src":item.imgUrl,"alt":""}})])])])])}),0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cloud"},[_c('div',{staticClass:"cloud-yun"},[_c('h2',[_vm._v("企课网 - 一站式企业实效培训云平台")]),_c('img',{attrs:{"src":require("../../assets/img/productcenterimg/heng.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"about-img"},[_c('img',{attrs:{"src":require("../../assets/img/myimg/qikevip.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"about-word"},[_c('span',[_vm._v("企课网--中国实效企业培训服务云平台，国家十五计划\"人才强国\"落地机构，企业组织成长解决方案供应商，企课网2017年8月1日有张凌先生创立，企课网创立的初心就是借助移动互联助力中国4000万家中小企业搭建自己的人才培育、协同办公云平台，加速企业高速发展。企课网以\"内容 + 工具 + 服务\"三位一体，为客户提供精准课程，大数据云计算管理分析，Al人工智能办公提速等技术，帮助企业有效改观传统人才培训模式的局限性，利用企课云让知识与师资有效共享，让更多的中小企业在企课网的帮助下，提高企业运作效率，帮助企业实现有效的人才培养，全面助力企业战略和企业文化落地，最终实现企业利润增长！")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"map",attrs:{"id":"main"}},[_c('div',{staticClass:"cloud"},[_c('div',{staticClass:"cloud-yun"},[_c('h2',{staticStyle:{"color":"#fff"}},[_vm._v("全国市场布局")]),_c('span',{staticStyle:{"color":"#fffefe","opacity":"0.8"}},[_vm._v("企课网总部在北京，已在上海，广州，深圳，南京， 杭州，郑州，太原，沈阳等多地设立分支机构")]),_c('img',{attrs:{"src":require("../../assets/img/productcenterimg/heng.png"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"develop"},[_c('div',{staticClass:"cloud"},[_c('div',{staticClass:"cloud-yun"},[_c('h2',[_vm._v("我们的发展历程")]),_c('img',{attrs:{"src":require("../../assets/img/productcenterimg/heng.png"),"alt":""}})])]),_c('div',[_c('div',{staticClass:"develop-word"},[_c('span',[_vm._v("企课网由连续成功创业者天使投资人张凌先生于2017年创立，公司定位做企服行业的携程、贝壳，公司以“教育强国”为愿景，以帮助千万家企业建立企业培训云平台提升业绩为使命，披荆斩棘，勇往直前。")])]),_c('div',{staticClass:"develop-coed"},[_c('div',[_c('h2',[_vm._v("300人")]),_c('span',[_vm._v("全国服务团队")])]),_c('div',[_c('h2',[_vm._v("1000+")]),_c('span',[_vm._v("实战导师团队")])]),_c('div',[_c('h2',[_vm._v("17000+")]),_c('span',[_vm._v("落地技能管理课")])]),_c('div',[_c('h2',[_vm._v("100000+")]),_c('span',[_vm._v("合作学习企业")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cloud"},[_c('div',{staticClass:"cloud-yun"},[_c('h2',[_vm._v("发展历程")]),_c('img',{attrs:{"src":require("../../assets/img/productcenterimg/heng.png"),"alt":""}})])])
}]

export { render, staticRenderFns }