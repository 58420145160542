<template>
	<div>
		<div class="cloud">
			<div class="cloud-yun">
				<h2>企课网 - 一站式企业实效培训云平台</h2>
				<!--  -->
				<img src="../../assets/img/productcenterimg/heng.png" alt="" />
			</div>
		</div>
		<div class="about-img">
			<img src="../../assets/img/myimg/qikevip.png" alt="" />
		</div>
		<div class="about-word">
			<span>企课网--中国实效企业培训服务云平台，国家十五计划"人才强国"落地机构，企业组织成长解决方案供应商，企课网2017年8月1日有张凌先生创立，企课网创立的初心就是借助移动互联助力中国4000万家中小企业搭建自己的人才培育、协同办公云平台，加速企业高速发展。企课网以"内容
				+ 工具 +
				服务"三位一体，为客户提供精准课程，大数据云计算管理分析，Al人工智能办公提速等技术，帮助企业有效改观传统人才培训模式的局限性，利用企课云让知识与师资有效共享，让更多的中小企业在企课网的帮助下，提高企业运作效率，帮助企业实现有效的人才培养，全面助力企业战略和企业文化落地，最终实现企业利润增长！</span>
			<!-- <span 
        >企课网，是国内实效型企业学习平台，公司致力于打造中国实效企业学习云平台。企课实效企业学习云平台借助互联网/移动互联网，助力中国千万家中小型企业搭建自己的企业人才培养系统。通过精品课程，大数据，云计算，有效改观传统人才培训模式的局限性，利用网络让知识与师资有效共享，让更多的中小企业在企课网的帮助下，提高企业运作效率，帮助企业实现有效的人才培养，全面助力企业战略和企业文化落地，最终实现企业利润增长！ 公司自成立以来，以“产品先行，销售致胜，用户为本”的企业经营理念，一步一个脚印发展成为行业的前行者，通过不断探索和创新，推动着国内企业培训互联网化进程。
</span
      > -->
		</div>
		<!-- map -->
		<div class="map" id="main">
			<div class="cloud">
				<div class="cloud-yun">
					<h2 style="color: #fff">全国市场布局</h2>
					<span style="color: #fffefe; opacity: 0.8;">企课网总部在北京，已在上海，广州，深圳，南京，
						杭州，郑州，太原，沈阳等多地设立分支机构</span>
					<img src="../../assets/img/productcenterimg/heng.png" alt="" />
				</div>
			</div>
			<!-- <img src="../../assets/img/myimg/map.png" alt="" /> -->
		</div>
		<!-- develop -->
		<div class="develop">
			<div class="cloud">
				<div class="cloud-yun">
					<h2>我们的发展历程</h2>
					<!--  -->
					<img src="../../assets/img/productcenterimg/heng.png" alt="" />
				</div>
			</div>
			<div>
				<div class="develop-word">
					<span>企课网由连续成功创业者天使投资人张凌先生于2017年创立，公司定位做企服行业的携程、贝壳，公司以“教育强国”为愿景，以帮助千万家企业建立企业培训云平台提升业绩为使命，披荆斩棘，勇往直前。</span>
					<!-- <span>
            企课网由资深企业培训行业从业者张凌于2017年在北京创立。公司用”教育强国”作为企业的愿景和使命，我们相信能够帮助国内千万家企业建立实效企业学习云平台，培养优秀人才，让企业有效的提高员工业务能力，赋能企业业绩增长。</span
          > -->
				</div>
				<div class="develop-coed">
					<div>
						<h2>300人</h2>
						<span>全国服务团队</span>
					</div>
					<div>
						<h2>1000+</h2>
						<span>实战导师团队</span>
					</div>
					<div>
						<h2>17000+</h2>
						<span>落地技能管理课</span>
					</div>
					<div>
						<h2>100000+</h2>
						<span>合作学习企业</span>
					</div>
				</div>
			</div>
		</div>
		<!--  -->
		<div style="margin-bottom: 30px">
			<div class="cloud">
				<div class="cloud-yun">
					<h2>发展历程</h2>
					<!--  -->
					<img src="../../assets/img/productcenterimg/heng.png" alt="" />
				</div>
			</div>
			<div>
				<div class="news" v-for="item in fazhanList" :key="item.id">
					<img src="../../assets/img/homeimg/ic_doc.png" class="quan" />
					<div class="month">{{item.title}}</div>
					<div class="newsert">
						<div class="newser">
							<div class="newserbg">
								<div class="shang">
									{{item.text}}
								</div>
								<img :src="item.imgUrl" alt="" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!--  -->
	</div>
</template>
<script>
	// import data from "@/assets/js/guanghan.json";
	import {getfazhanlist} from '../../api/api.js';
	export default {
		name: "echarts",
		data() {
			return {
				fazhanList:[]
			};
		},
		mounted() {
			// this.echartsInit();
		},
		created() {
			this.getlist()
		},
		methods: {
			getlist(){
				getfazhanlist().then(res=>{
					var _this = this;
					res.data.data.forEach(item=>{
						let obj = {
							id : item.id,
							title: item.course_time,
							text: item.description,
							imgUrl: 'http://qkw-api.oss-cn-beijing.aliyuncs.com/' + item.img,
						}
						_this.fazhanList.push(obj)
					})
				})
			},
		},
	};
</script>

<style lang="stylus" scoped>
	.newserbg {
		margin-left 16px 
		box-shadow: 3px 1px 20px 2px rgba(153, 153, 153, 0.13) 
		width 3.1rem 
		border-radius: 4px;
	}

	.cloud {
		display flex 
		justify-content center 
		text-align center 
		line-height 20px 
		.cloud-yun {
			margin-top 0.3rem 
			h2 {
				font-size: 0.19rem;
				font-family: PingFang SC;
				font-weight: 800;
				color: #212121;
				line-height: 26px;
			}

			span {
				display block 
				line-height: 18px 
				color #999 
				font-weight: 400 
				width 2.9rem 
				margin-top 0.1rem
			}

			img {
				width 0.38rem 
				height 0.03rem
			}
		}
	}

	.about-img {
		text-align center 
		margin-top 0.1rem 
		img {
			width 3.45rem 
			height 1.82rem
		}
	}

	.about-word {
		margin auto 
		margin-top 16px 
		width 3.45rem 
		margin-bottom 20px 
		span {
			font-size: 15px;
			font-family: PingFang SC;
			font-weight: 500;
			color: #333333;
			line-height: 0.24rem;
		}
	}

	.develop-word {
		width: 3.45rem;
		font-size: 15px;
		font-family: PingFang SC;
		font-weight: 500;
		color: #333333;
		line-height: 0.24rem;
		margin auto
	}

	.develop-coed {
		display flex 
		text-align center 
		justify-content: space-around;
		flex-wrap: wrap 
		align-content: stretch 
		div {
			width 1.8rem 
			height 0.6rem 
			margin-top 29px
		}

		h2 {
			color red 
			font-size 25px
		}

		span {
			font-size 16px
		}
	}

	.news {
		.month {
			font-size: 16px;
			font-family: DIN;
			font-weight 800 
			color: #000;
			display inline 
			margin-left 0.1rem 
			height 0.3rem
		}

		.quan {
			margin-left 0.12rem 
			width 0.09rem 
			height 0.09rem
		}
	}

	.news {
		margin-top 20px 
		.newser {
			margin-left 0.15rem 
			border-left 0.5px solid #D2D2D2 
			padding-top 20px 
			img {
				width: 2.6rem 
				border-radius: 10px;
				margin 15px 24px
			}

			.shang {
				width: 2.53rem 
				margin -5px 27px 
				font-size: 0.15rem;
				font-family: PingFang SC;
				font-weight: 400;
				color: #212121;
				line-height: 0.2rem;
				padding-top 20px
			}
		}
	}

	#main {
		width: 100% 
		height 4.8rem;
		background url(../../assets/img/myimg/map1.png) 
		background-size 100% 100%
	}
</style>
