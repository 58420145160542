<template>
  <div>
    <div class="call-me">
      <div class="callnum">
        <img src="../../assets/img/myimg/ic_call.png" alt="" />
        <span>400-658-6758</span>
      </div>
      <div class="cn">全国服务热线 7*24小时服务</div>
    </div>
    <div class="message">
      <div>
        <h2>留言反馈</h2>
        <span>Online consulting</span>
      </div>
      <div class="enterprise-deng" style=""><span>*</span>姓名：</div>
      <input type="text" name="" v-model="username" placeholder="请输入真实姓名" />
      <div class="enterprise-deng" style=""><span>*</span>邮箱：</div>
      <input type="text" name="" v-model="email" placeholder="请输入邮箱" />
      <div class="enterprise-deng" style=""><span>*</span>电话：</div>
      <input type="text" name="" v-model="mobile" placeholder="请输入电话" />
      <div class="enterprise-deng" style=""><span>*</span>反馈信息：</div>
      <input type="text" name="" v-model="content" placeholder="请输入反馈信息" style="height:70px"/>
      <div class="button" @click="feedback">提交</div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { Toast } from "vant";
import { Feedback } from "@/api/api";

export default {
  data() {
    return {
      username: "",
      mobile: "",
      email: "",
      content: "",
      screenHeightNochange: true,
    };
  },
  methods: {
    feedback() {
      let reg = /^(((13[0-9]{1})|(14[0-9]{1})|(15[0-9]{1})|(16[0-9]{1})|(17[0-9]{1})|(19[0-9]{1})|(18[0-9]{1}))+\d{8})$/;
      let emit = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/;
      let { username, mobile, email, content } = this;
      if (username == "") {
        Toast("请填入你的姓名");
        return false;
      }
      if (!emit.test(email)) {
        Toast("请输入正确的邮箱");
        return false;
      }
      if (!reg.test(mobile)) {
        Toast("请输入正确的手机号");
        return false;
      }
      if (content == "") {
        Toast("请输入您的反馈信息");
        return false;
      }
      let param = new URLSearchParams();
      param.append("username", username);
      param.append("mobile", mobile);
      param.append("email", email);
      param.append("content", content);
      let header = {
        headers: { "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8" },
      };
      axios.post(Feedback(), param, header).then((res) => {
        if (res.status == 200) {
          Toast.success("提交成功");
          this.username = "";
          this.mobile = "";
          this.email = "";
          this.content = "";
        } else {
          Toast(res.data.msg);
        }
      });
    },
  },
  mounted() {
    const self = this;
    window.onresize = () => {
      if (self.oldFullHeight) {
        self.screenHeightNochange =
          document.documentElement.clientHeight === self.oldFullHeight;
      }
    };
  },
};
</script>

<style lang="stylus" scoped>
.call-me
  height 1.3rem
  border-bottom 1px solid #EEEEEE
.message
  text-align center
  line-height 30px
  padding-top 30px
  span
    color  #999999
.callnum
  text-align center
  padding-top 40px
  display flex
  justify-content: center
  align-items: flex-start
  img
    width 15px
    line-height 50px
    margin-right: 10px
    margin-top 3px
  span
    font-size: 19px;
    font-family PingFang SC;
    font-weight 800;
    color  #F85849;
.cn
  text-align center
  padding-top 20px
  font-weight 700
  color #323232
input
  width: 3.25rem;
  height: 0.4rem;
  background: #FFFFFF;
  border: 1px solid #D5D5D5;
  border-radius: 5px;
  padding-left: 10px;
  font-size 15px
input::placeholder
  font-size 14px
input::type
  font-size 18px

.button
  margin  20px auto 20px
  width: 2.82rem;
  height: 0.44rem;
  background: linear-gradient(90deg, #FA7C72 0%, #F85346 100%);
  border-radius: 22px;
  text-align center
  line-height 0.45rem
  color #fff

.enterprise-deng
  text-align left
  margin-left 20px
  margin-top 12px
  line-height 18px
  font-size 15px
  margin-bottom 9px
  span
    color red
</style>
